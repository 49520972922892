<template>
  <div>
    <button id="markets-btn" class="btn btn-secondary dropdown-toggle ddb dropdown-toggle dropdown-filter" type="button"
      data-toggle="modal" data-target="#marketsModal">
      {{ selectedMarket }}
    </button>
    <div class="modal fade" id="marketsModal" tabindex="-1" aria-labelledby="marketsModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-dark" id="exampleModalLabel">Markets</h5>
            <span type="button" class="close" data-dismiss="modal">&times;</span>
          </div>
          <div style="font-size: medium;text-align:center;" class="row modal-body text-center">
            <div style="margin: 1px;background-color: #101B21;color: white;width:auto;"
              class="btn btn-sm col text-center">
              <a @click="setMarket(-1, 'Markets')">
                <div class="item-wrapper text-white">Markets</div>
              </a>
            </div>
            <div style="margin: 1px;background-color: #101B21;color: white;width:auto;"
              class="btn btn-sm col text-center" v-for="s in markets" v-bind:key="getKey(s.market_id)"
              v-bind:class="getMarketClass(s.market_id)" @click="setMarket(s)">
              <div>
                <div class="item-wrapper">
                  <span class="ml-2 item-wrapper-span-active text-white" v-text="s.market_name"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fix from "@/services/fix";
export default {
  name: "Markets",
  data() {
    return {
      markets: [],
      market: {},
      competition_name: 'Top League',
      sport_name: 'Sports',
      market_name: 'Market',
      market_id: 1,
      selected_hour: 0,
      hours: [-1, 1, 3, 6, 12, 24, 48],
      selectedMarket: 'Markets',
    }
  },
  methods: {
    async getMarket() {
      var path = process.env.VUE_APP_URL_MARKETS;
      try {
        const response = await fix.get(path, {
          headers: {
            'accept': 'application/json'
          }
        });
        this.markets = response.data;
      } catch (error) {
        console.error('There was an error fetching the markets:', error);
      }
    },
    getKey(match_id) {
      var prefix = "popular-games-" + match_id;
      return Math.random().toString(10).replace('0.', 'odd-id-' + prefix + '-');
    },
    setMarket(market) {
      document.getElementById("markets-btn").click();

      this.market = market;
      this.market_id = market.market_id;
      this.market_name = market.market_name;
      if (market === -1) {
        this.selectedMarket = "Markets";
        this.$store.dispatch("setMarketID", 0);
        return;
      }
      this.selectedMarket = market.market_name.length > 11 ? market.market_name.substring(0, 11) : market.market_name;
      this.$store.dispatch("setMarketID", market.market_id);
    },
    getMarketClass(marketID) {
      return parseInt(marketID) === parseInt(this.market_id) ? 'market-list-active' : 'market-list';
    },
    setHour(h) {
      this.selected_hour = h;
      this.$store.dispatch("setHour", h);
    },
    humanHourName(h) {
      if (h === -1) {
        return "All Time";
      }
      return h === "" || h === "" || h === 0 ? "Time" : h + " Hours";
    },
    setLeague(league) {
      document.getElementById("leagues-btn").click();
      if (league === -1) {
        this.competition_name = "Leagues";
        this.$store.dispatch("setCompetitionID", 0);
        return;
      }
      this.competition_name = league.country_name + " - " + league.competition;
      this.league = league;
      this.competition_name = league.competition;
      this.$store.dispatch("setCompetitionID", league.competition_id);
    },
    setSport(sportID) {
      this.sport_name = sportID.sport_name;
      document.getElementById("sports-btn").click();
      this.$store.dispatch("setSportID", sportID);
      if (this.$store.state.current_page === "home") return;
      this.$router.push({ name: "home" });
    },
    getMenuTextClass(sportID) {
      return parseInt(this.$store.state.sport_id) === parseInt(sportID)
        ? "ml-2 item-wrapper-span-active"
        : "ml-2 item-wrapper-span";
    },
  },
  computed: {
    leagues() {
      if (
        this.$store.state.leagues !== undefined &&
        this.$store.state.leagues !== null
      ) {
        return this.$store.state.leagues;
      }
      return this.$store.state.leagues;
    },
    sports() {
      var s = this.$store.state.sports;
      var t = [];
      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });
      return t;
    },
  },
  watch: {
    sport_id(newValue, oldValue) {
      if (parseInt(newValue) === parseInt(oldValue)) {
        return;
      }
      this.getPopularGames();
    },
  },
  mounted() {
    this.getMarket();

  }
}
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);
}

.list-active {
  background-color: #F08B05;
}
</style>
